// export const apiUrl = `https://eduvels.tech4lyf.com:1111/`;

// export const  apiShapes = `https://eduvels.tech4lyf.com:1111/shapes`;

// export const apiLocal = `http://192.168.29.93:1111/`;


export const apiUrl = `https://eduvels-api.taskgrids.com/`;

export const  apiShapes = `https://eduvels-api.taskgrids.com/shapes`;

export const apiLocal = `https://eduvels-api.taskgrids.com/`;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Text } from "react-konva";

const TextDisplay = ({
  text,
  isSelected,
  onSelect,
  onDragStart,
  onDragEnd,
  onChange,
  shapeProps,
  stageRef,
  activeRef,
  setFont,
  setSelectedId,
  layerRef,
}) => {
  const textRef = useRef();
  const trRef = useRef();

  const stage = stageRef.current;
  const layer = layerRef.current;

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.moveToTop();
      trRef.current.nodes([textRef.current]);
      trRef.current.getLayer().batchDraw();
      console.log(textRef);
      console.log(text);
      activeRef.current = textRef.current;
    }
  }, [isSelected, activeRef]);

  useEffect(() => {
    if (textRef.current.attrs.type === 'text') {
      textRef.current.moveToTop();
      textRef.current.attrs.fontSize = 20;
      textRef.current.attrs.fontFamily = "Arial";
      textRef.current.attrs.fill = "black";
      textRef.current.attrs.letterSpacing = 1;
      textRef.current.attrs.lineHeight = 1;
      textRef.current.attrs.width = 500;
      textRef.current.attrs.textDecoration = "";
      textRef.current.attrs.fontStyle = "";
      textRef.current.attrs.align = "center";
      setSelectedId(text.hash);
    }
    if (textRef.current.attrs.type === 'Trace') {
      textRef.current.moveToTop();
      textRef.current.attrs.fontSize = 80;
      textRef.current.attrs.fontFamily = "Raleway Dots";
      textRef.current.attrs.fill = "black";
      textRef.current.attrs.width = 60;
      textRef.current.attrs.height = 80;
      textRef.current.attrs.textDecoration = "";
      textRef.current.attrs.letterSpacing = 1;
      textRef.current.attrs.fontStyle = "";
      textRef.current.attrs.align = "";
      // setSelectedId(text.hash);  
    }
    if (textRef.current.attrs.type === 'text1') {
      textRef.current.moveToTop();
      textRef.current.attrs.fontSize = 20;
      textRef.current.attrs.fontFamily = "Arial";
      textRef.current.attrs.fill = "black";
      textRef.current.attrs.letterSpacing = 1;
      textRef.current.attrs.lineHeight = 1;
      textRef.current.attrs.width = 50;
      textRef.current.attrs.textDecoration = "";
      textRef.current.attrs.fontStyle = "";
      textRef.current.attrs.align = "";
      // setSelectedId(text.hash);
    }
    if (textRef.current.attrs.type === 'number') {
      textRef.current.moveToTop();
      textRef.current.attrs.fontSize = 32;
      textRef.current.attrs.fontFamily = "Arial";
      textRef.current.attrs.fill = "black";
      textRef.current.attrs.width = 80;
      textRef.current.attrs.textDecoration = "";
      textRef.current.attrs.fontStyle = "";
      textRef.current.attrs.letterSpacing = 1;
      textRef.current.attrs.align = "";
      setSelectedId(text.hash);
    }
  }, []);

  const onDblClick = () => {

    const textNode = textRef.current;
    const tr = trRef.current;

    textNode.hide();
    // tr.hide();
    layer.draw();

    let rotation = textNode.rotation();
    let scale = textNode.getAbsoluteScale().x;

    const textPosition = textNode.absolutePosition();

    const areaPosition = {
      x: stage.container().offsetLeft + textPosition.x,
      y: stage.container().offsetTop + textPosition.y
    };

    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);

    textarea.value = textNode.text();

    console.log(textNode.text());
    console.log(textNode);

    Object.assign(textarea.style, {
      position: "absolute",
      top: areaPosition.y + "px",
      left: areaPosition.x + "px",
      width: textNode.width() - textNode.padding() * 2 + "px",
      height: textNode.height() - textNode.padding() * 2 + 5 + "px",
      fontSize: textNode.fontSize() * scale + "px",
      textDecoration: textNode.textDecoration(),
      fontStyle: textNode.fontStyle(),
      align: textNode.align(),
      border: "none",
      padding: "0px",
      margin: "0px",
      overflow: "hidden",
      background: "none",
      outline: "none",
      resize: "none",
      lineHeight: textNode.lineHeight(),
      letterSpacing: textNode.letterSpacing(),
      fontFamily: textNode.fontFamily(),
      fill: textNode.fill(),
      transformOrigin: "left top",
      textAlign: textNode.align(),
      rotation: textNode.rotation()
    });

    let transform = "";
    if (rotation) {
      transform += "rotateZ(" + rotation + "deg)";
    }

    var px = 0;
    // also we need to slightly move textarea on firefox
    // because it jumps a bit
    var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (isFirefox) {
      px += 2 + Math.round(textNode.fontSize() / 20);
    }
    transform += "translateY(-" + px + "px)";

    textarea.style.transform = transform;

    // reset height
    textarea.style.height = "auto";
    // after browsers resized it we can set actual value
    textarea.style.height = textarea.scrollHeight + 3 + "px";

    textarea.focus();

    function removeTextarea() {
      textarea.parentNode.removeChild(textarea);
      window.removeEventListener("click", handleOutsideClick);
      textNode.show();
      // tr.show();
      layer.draw();
    }

    textarea.addEventListener("keydown", function (e) {
      // hide on enter
      // but don't hide on shift + enter
      if (e.keyCode === 13 && e.shiftKey) {
        textNode.text(textarea.value);
        removeTextarea();
      }
      // on esc do not set value back to node
      if (e.keyCode === 27) {
        removeTextarea();
      }
    });

    textarea.addEventListener("keydown", function (e) {
      scale = textNode.getAbsoluteScale().x;
      // setTextareaWidth(textNode.width() * scale);
      textarea.style.height = "auto";
      textarea.style.height =
        textarea.scrollHeight + textNode.fontSize() + "px";
    });

    function handleOutsideClick(e) {
      if (e.target !== textarea) {
        textNode.text(textarea.value);
        removeTextarea();
      }
    }
    setTimeout(() => {
      window.addEventListener("click", handleOutsideClick);
    });

  };

  const clearFocus = (shape) => {
    shape.setStroke("transparent");
    layerRef.current.draw();
  };

  return (
    <Fragment>
      <Text
        onDragStart={onDragStart}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y()
          });
        }}
        onMouseEnter={(event) => {
          stageRef.current.container().style.cursor = "move";
        }}
        onMouseLeave={(event) => {
          const shape = event.target;
          stageRef.current.container().style.cursor = "default";
          if (!isSelected) {
            clearFocus(shape);
          }
        }}
        onDragMove={(event) => {
          const shape = event.target;
          clearFocus(shape);
          onSelect(event);
        }}
        onClick={(event) => {
          const shape = event.target;
          onSelect(event);
          // onSelect(textRef);
          clearFocus(shape);
        }}
        onTransformEnd={(e) => {
          const node = textRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY)
          });
        }}
        onTap={() => onSelect(textRef)}
        name="rectangle"
        ref={textRef}
        text={text.text}
        x={text.x}
        y={text.y}
        fontFamily={text.fontFamily}
        textDecoration={text.textDecoration}
        fontStyle={text.fontStyle}
        fill={text.fill}
        align={text.align}
        draggable
        type={text.type}
        width={text.width}
        height={text.height}
        onDblClick={onDblClick}
        onTransform={() => {
          const textNode = textRef.current;
          textNode.setAttrs({
            width: textNode.width() * textNode.scaleX(),
            height: textNode.height() * textNode.scaleY(),
            scaleX: 1,
            scaleY: 1
          });
        }}
      />
    </Fragment>
  );
};

export default TextDisplay;

import React from 'react'
import "../App.css"
const Respo = () => {
  return (<>
    
    <div className="non-desktop">
    <div className="mob-view">
        <p className="respo-content">
            This site is  open desktop only
        </p>
    </div>
 </div>


    </>)
}

export default Respo
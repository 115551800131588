import React, { useState, useEffect } from "react";
import logo from '../assests/home/logos.svg';
import home_icon from '../assests/home/home_icon.svg';
import template_icon from '../assests/home/template_icon.svg';
import projects_icon from '../assests/home/projects_icon.svg'
import searchIcon from '../assests/home/searchIcon.svg';
import share from '../assests/home/share.svg';
import edits from '../assests/home/edit.svg';
import Delete from '../assests/home/delete.gif';
import '../demo.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl, apiLocal } from "../constant/constant";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button as Button1
} from '@chakra-ui/react';
import { useDisclosure } from "@chakra-ui/react";
import { IndeterminateCheckBoxTwoTone } from "@mui/icons-material";

function Home() {
    const navigate = useNavigate();
    const cancelRef = React.useRef();
    const [searchInput, setSearchInput] = useState("");
    const [recent, setRecent] = useState([]);
    const [templateImage, setTemplateImage] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchTemp, setSearchTemp] = useState([]);
    const [deleteById, setDeleteById] = useState();
    const [reload, setReload] = useState();
    const [shared, setShared] = useState([]);
    const [bool, setBool] = useState(false);
    const [editById, setEditById] = useState();
    const [editing, setEditing] = useState(false);
    const [editedData, setEditedData] = useState("Question Paper");
    const { onClose, isOpen, onOpen } = useDisclosure();


    useEffect(() => {
        const email = localStorage.getItem('data');
        axios.get(`${apiLocal}recent/${email}`).then((res) => {
            setRecent(res.data)
            console.log(res);
        })
    }, [reload]);

    useEffect(() => {
        axios.get(`${apiUrl}template`).then((res) => {
            for (let i = 0; i < res.data.length; i++) {
                setTemplateImage((current) => [...current, res.data[i]])
            }
        })

        const email = localStorage.getItem('data')
        axios.get(`${apiLocal}shared/${email}`).then((res) => {
            setShared(res.data);
            console.log(res.data);
        })

    }, []);


    useEffect(() => {
        if (searchInput) {
            for (let i = 0; i < recent.length; i++) {
                const search1 = recent[i].subject.includes(searchInput)
                console.log(search1)
                if (search1 === true) {
                    setSearch((current) => [...current, recent[i]])
                    console.log(recent[i]);
                }
            }
            for (let i = 0; i < templateImage.length; i++) {
                const search1 = templateImage[i].tmpname.includes(searchInput)
                console.log(search1)
                if (search1 === true) {
                    setSearchTemp((current) => [...current, templateImage[i]])
                    console.log(templateImage[i]);
                }
            }
        }
    }, [searchInput])

    const deleteImg = (item) => {
        axios.delete(`${apiUrl}recent?id=${item}`).then((res) => {
            setReload(res)
        });
        onClose();
        // window.location.reload();
    }

    const logoutHandler = e => {
        e.preventDefault();
        localStorage.removeItem('auth')
        localStorage.removeItem('data')
        navigate('/')
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        setSearch([])
        setSearchTemp([])
    };

    const handleEditClick = (e) => {
        setEditing(true);
        // setEditedData(data);
    }

    const data = localStorage.getItem('data');
    const firstName = data.substring(0, data.lastIndexOf("@"));
    const initial = data.substr(0, 1);

    return (<>
            <Navbar bg="light" expand="lg" className="navbar">
                <Container fluid>
                    <Navbar.Brand ><img src={logo} className="logo" alt='react logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll></Nav>
                        <Form className="d-flex navbarRight">
                            <Button className="btn createBtn" onClick={() => navigate('/dashboard')}>Create New</Button>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                    <p className='profileBack'>{initial}</p>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1" onClick={logoutHandler}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="sideTab">
                    <Col sm={2}>
                        <div className='sideTab_profile'>
                            <p className='initial'>{initial}</p>
                            <p className='fullName'>{firstName}</p>
                        </div>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first" className='tabTitle home'>
                                    <img className='tab_icon' src={home_icon} alt="home" />
                                    Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second" className='tabTitle template'>
                                    <img className='tab_icon' src={template_icon} alt="template" />
                                    Templates</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third" className='tabTitle projects'>
                                    <img className='tab_icon' src={projects_icon} alt="home" />
                                    Projects</Nav.Link>
                            </Nav.Item>
                            {shared.length > 0 ?
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth" className={bool === true ? 'tabTitle' : 'tabTitle sharedProjects'} onClick={() => { setBool(true) }}>
                                        <img className='tab_icon' src={share} alt="home" />
                                        Shared</Nav.Link>
                                </Nav.Item> : ''}
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className='searchBox_main'>
                                    <div className='searchBox_inner'>
                                        <p className='searchText'>Search Recent Projects</p>
                                        <div className="searchBoxMain">
                                            <img className='searchIcon' src={searchIcon} alt="search_icon" />
                                            <input type={'search'} className="searchBox" onChange={handleChange} value={searchInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className='recentProjects'>
                                    {recent.length > 0 ? <p className='title'>Recent Projects</p> : <p className='title'>No Recent Projects</p>}
                                    <Row className="row">
                                        {searchInput.length > 0 ?
                                            search?.map((item, index) => {
                                                const date = new Date();
                                                const ISToffSet = 330;
                                                const offset = ISToffSet * 60 * 1000;
                                                const ISTTime = new Date(date.getTime() + offset);
                                                const date1 = new Date(item.createdAt.slice(0, 10));
                                                const date2 = new Date(ISTTime.toJSON().slice(0, 10));
                                                const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10)
                                                if (diffDays <= 15) {
                                                    return (
                                                        <Col sm={2} key={index}>
                                                            <Card className="card">
                                                                <Link to='/dashboard' state={{ item }}>
                                                                    <Card.Img variant="top" src={item.img[0]} />
                                                                </Link>
                                                            </Card>
                                                            <p className='questionPaper'>{`PRJ${item.subject}`}</p>
                                                        </Col>
                                                    )
                                                }
                                            }) : recent?.map((item, index) => {
                                                const date = new Date();
                                                const ISToffSet = 330;
                                                const offset = ISToffSet * 60 * 1000;
                                                const ISTTime = new Date(date.getTime() + offset);
                                                const date1 = new Date(item.createdAt.slice(0, 10));
                                                const date2 = new Date(ISTTime.toJSON().slice(0, 10));
                                                const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10)
                                                if (diffDays <= 15) {
                                                    return (
                                                        <Col sm={2} key={index}>
                                                            <Card className="card">
                                                                <Link to='/dashboard' state={{ item }}>
                                                                    <Card.Img variant="top" src={item.img[0]} />
                                                                </Link>
                                                            </Card>
                                                            <p className='questionPaper'>{`PRJ${item.subject}`}</p>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className='searchBox_main'>
                                    <div className='searchBox_inner'>
                                        <p className='searchText'>Search Template</p>
                                        <div className="searchBoxMain">
                                            <img className='searchIcon' src={searchIcon} alt="search_icon" />
                                            <input type={'search'} className="searchBox" onChange={handleChange} value={searchInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className='recentProjects'>
                                    {templateImage.length > 0 ? <p className='title'>Templates</p> : <p className='title'>No Templates</p>}
                                    <Row className="row">
                                        {searchInput.length > 0 ? searchTemp?.map((item, index) => (
                                            <Col sm={2} key={index}>
                                                <Card className="card">
                                                    <Card.Img variant="top" src={item.img.map((data) => data)} />
                                                </Card>
                                                <p className='questionPaper'>{`TMP${item.tmpname}`}</p>
                                            </Col>
                                        )) : templateImage?.map((item, index) => (
                                            <Col sm={2} key={index}>
                                                <Card className="card">
                                                    <Card.Img variant="top" src={item.img.map((data) => data)} />
                                                </Card>
                                                <p className='questionPaper'>{`TMP${item.tmpname}`}</p>
                                            </Col>
                                        )
                                        )}
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className='searchBox_main'>
                                    <div className='searchBox_inner'>
                                        <p className='searchText'>Search Projects</p>
                                        <div className="searchBoxMain">
                                            <img className='searchIcon' src={searchIcon} alt="search_icon" />
                                            <input type={'search'} className="searchBox" onChange={handleChange} value={searchInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className='recentProjects'>
                                    {recent.length > 0 ? <p className='title'>Projects</p> : <p className='title'>No Projects</p>}
                                    <Row className="row">
                                        {searchInput.length > 0 ?
                                            search?.map((item, index) => (
                                                <Col sm={2} key={index}>
                                                    <Card className="card">
                                                        <Link to='/dashboard' state={{ item }}>
                                                            <Card.Img variant="top" src={item.img[0]} />
                                                        </Link>
                                                        <img className="deleteIcon" src={Delete} alt="delte_icon" onClick={() => {
                                                            onOpen()
                                                            setDeleteById(item._id)
                                                        }} />
                                                        <AlertDialog
                                                            motionPreset='slideInBottom'
                                                            leastDestructiveRef={cancelRef}
                                                            onClose={onClose}
                                                            isOpen={isOpen}
                                                            isCentered
                                                        >
                                                            <AlertDialogOverlay />
                                                            <AlertDialogContent>
                                                                <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
                                                                <AlertDialogCloseButton />
                                                                <AlertDialogBody>
                                                                    Are you sure you want to delete this Project ?
                                                                </AlertDialogBody>
                                                                <AlertDialogFooter>
                                                                    <Button1 ref={cancelRef} onClick={onClose}>
                                                                        No
                                                                    </Button1>
                                                                    <Button1 colorScheme='red' ml={3} onClick={() => deleteImg(deleteById)}>
                                                                        Delete
                                                                    </Button1>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                    </Card>
                                                    <input className='questionPaper' value={item.subject} />
                                                </Col>
                                            )) : recent?.map((item, index) => (
                                                <Col sm={2} key={index}>
                                                    <Card className="card">
                                                        <Link to='/dashboard' state={{ item }}>
                                                            <Card.Img variant="top" src={item.img[0]} />
                                                        </Link>
                                                        <img className="deleteIcon" src={Delete} alt="delte_icon" onClick={() => {
                                                            onOpen()
                                                            setDeleteById(item._id)
                                                        }} />
                                                        <AlertDialog
                                                            motionPreset='slideInBottom'
                                                            leastDestructiveRef={cancelRef}
                                                            onClose={onClose}
                                                            isOpen={isOpen}
                                                            isCentered
                                                        >
                                                            <AlertDialogOverlay />
                                                            <AlertDialogContent>
                                                                <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
                                                                <AlertDialogCloseButton />
                                                                <AlertDialogBody>
                                                                    Are you sure you want to delete this Project ?
                                                                </AlertDialogBody>
                                                                <AlertDialogFooter>
                                                                    <Button1 ref={cancelRef} onClick={onClose}>
                                                                        No
                                                                    </Button1>
                                                                    <Button1 colorScheme='red' ml={3} onClick={() => deleteImg(deleteById)}>
                                                                        Delete
                                                                    </Button1>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                    </Card>
                                                    <div style={{ display: "flex" }}>
                                                        {!editing && (
                                                            
                                                                // <img className="editIcon" src={edits} alt="delte_icon" onClick={() => {
                                                                //     setEditById(item._id)
                                                                // }} />
                                                                 <input
                                                                    placeholder={item.subject}
                                                                    onChange={(e)=>{
                                                                        setEditedData(e.target.value)}
                                                                    }
                                                                    onBlur={(e)=>{
                                                                        axios.put(`${apiLocal}recent`, {id:item._id,subject: editedData} ).then((res) => {
                                                                           console.log(res.data);
                                                                       })
                                                                   }}
                                                                />
                                                            )}
                                                    </div>
                                                </Col>
                                            )
                                            )}
                                    </Row>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <div className='searchBox_main'>
                                    <div className='searchBox_inner'>
                                        <p className='searchText'>Shared Project</p>
                                        <div className="searchBoxMain">
                                            <img className='searchIcon' src={searchIcon} alt="search_icon" />
                                            <input type={'search'} className="searchBox" onChange={handleChange} value={searchInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className='recentProjects'>
                                    {shared.length > 0 ? <p className='title'>Shared Projects</p> : <p className='title'>No Shared Projects</p>}
                                    <Row className="row">
                                        {searchInput.length > 0 ?
                                            search?.map((item, index) => (
                                                <Col sm={2} key={index}>
                                                    <Card className="card">
                                                        <Link to='/dashboard' state={{ item }}>
                                                            <Card.Img variant="top" src={item.img[0]} />
                                                        </Link>
                                                        <img className="deleteIcon" src={Delete} alt="delte_icon" onClick={() => {
                                                            onOpen()
                                                            setDeleteById(item._id)
                                                        }} />
                                                        <AlertDialog
                                                            motionPreset='slideInBottom'
                                                            leastDestructiveRef={cancelRef}
                                                            onClose={onClose}
                                                            isOpen={isOpen}
                                                            isCentered
                                                        >
                                                            <AlertDialogOverlay />
                                                            <AlertDialogContent>
                                                                <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
                                                                <AlertDialogCloseButton />
                                                                <AlertDialogBody>
                                                                    Are you sure you want to delete this Project ?
                                                                </AlertDialogBody>
                                                                <AlertDialogFooter>
                                                                    <Button1 ref={cancelRef} onClick={onClose}>
                                                                        No
                                                                    </Button1>
                                                                    <Button1 colorScheme='red' ml={3} onClick={() => deleteImg(deleteById)}>
                                                                        Delete
                                                                    </Button1>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                    </Card>
                                                    <p className='questionPaper'>{`PRJ${item.subject}`}</p>
                                                </Col>
                                            )) : shared?.map((item, index) => (
                                                <Col sm={2} key={index}>
                                                    <p className='questionPaper1'>{`Shared By ${item.email.substring(0, item.email.lastIndexOf("@"))}`}</p>
                                                    <Card className="card">
                                                        <Link to='/dashboard' state={{ item }}>
                                                            <Card.Img variant="top" src={item.img[0]} />
                                                        </Link>
                                                        <img className="deleteIcon" src={Delete} alt="delte_icon" onClick={() => {
                                                            onOpen()
                                                            setDeleteById(item._id)
                                                        }} />
                                                        <AlertDialog
                                                            motionPreset='slideInBottom'
                                                            leastDestructiveRef={cancelRef}
                                                            onClose={onClose}
                                                            isOpen={isOpen}
                                                            isCentered
                                                        >
                                                            <AlertDialogOverlay />
                                                            <AlertDialogContent>
                                                                <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
                                                                <AlertDialogCloseButton />
                                                                <AlertDialogBody>
                                                                    Are you sure you want to delete this Project ?
                                                                </AlertDialogBody>
                                                                <AlertDialogFooter>
                                                                    <Button1 ref={cancelRef} onClick={onClose}>
                                                                        No
                                                                    </Button1>
                                                                    <Button1 colorScheme='red' ml={3} onClick={() => deleteImg(deleteById)}>
                                                                        Delete
                                                                    </Button1>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                    </Card>
                                                    <p className='questionPaper'>{`${item.subject}`}</p>
                                                </Col>
                                            )
                                            )}
                                    </Row>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </>
    )
}
export default Home;